#app-wrapper {
  max-width: 1000px;
  margin: auto;
  padding: 0 4rem;
}

#title-row {
  padding: 4rem 0;
  position: relative;
}

#page-title {
  font-family: 'DM Serif Text', serif;
  font-size: 2.5rem;
  color: var(--gray-dark);
}

#page-subtitle {
  font-size: 1.5rem;
  color: var(--gray);
}

#title-separator {
  width: 120px;
  height: 4px;
  background-color: var(--yellow);
}

.divider {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 0.5rem;
}

#share-prompt {
  transition: all 0.3s cubic-bezier(.4, 0, .2, 1);
  font-size: 0.75rem;
  color: #1b95e0;
  position: absolute;
  right: 20px;
  bottom: -35px;
  width: 50%;
  text-align: right;
  font-weight: bold;
  opacity: 0;
  transform: translateY(10px);
}

#share-prompt.shown {
  opacity: 1;
  transform: none;
}

.chart {
  padding: 40px;
  margin: -40px;
  margin-bottom: 0;
  padding-bottom: 0;
  overflow: hidden;
}

footer {
  margin-top: 60px;
  padding: 40px 0;
  color: var(--gray);
  font-size: 0.75rem;
}

footer p {
  margin-bottom: 0.5rem;
}

@media (prefers-color-scheme: dark) {
  #page-title {
    color: white;
  }
}

@media (max-width: 700px) {
  #app-wrapper {
    padding: 0 2rem;
  }

  #title-row {
    padding: 2rem 0 3rem 0;
  }

  #page-title {
    font-size: 2rem;
  }

  #page-subtitle {
    font-size: 1.25rem;
  }

  #title-separator {
    width: 100px;
    height: 3px;
  }
}

@media (max-width: 500px) {
  #app-wrapper {
    padding: 0 1rem;
  }

  #title-row {
    padding: 1rem 0 3rem 0;
  }

  #page-title {
    font-size: 1.75rem;
  }

  #page-subtitle {
    font-size: 1rem;
  }

  #title-separator {
    width: 80px;
    height: 2px;
  }

  #share-prompt {
    width: 70%;
  }

  footer {
    padding: 10px 0;
  }
}
