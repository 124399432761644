#chart-control {
  justify-content: space-between;
  margin-bottom: 2rem;
}

#mode-control button {
  padding-left: 1rem;
  padding-right: 0.75rem;
}

#mode-control button:after {
  margin: 0 0.125rem 0 0.75rem;
}

#mode-control .dropdown-item {
  padding-left: 1rem;
  padding-right: 1rem;
}

#scale-toggle button {
  color: var(--gray-dark);
  text-decoration: none;
  padding-left: 0;
  padding-right: 0;
  margin: 0 0.5rem;
  opacity: 0.3;
  border-bottom: 2px solid transparent;
  font-weight: bold;
  text-transform: uppercase;
}

#scale-toggle button:hover {
  opacity: 0.6;
}

#scale-toggle button.selected {
  border-bottom: 2px solid var(--yellow);
  opacity: 1;
}

#zoom-control svg {
  width: 20px;
  color: var(--gray-dark);
}

@media (prefers-color-scheme: dark) {
  #scale-toggle button, #zoom-control svg {
    color: white;
  }
}

@media (max-width: 500px) {
  #mode-control button {
    padding-left: 0.5rem;
    padding-right: 0.25rem;
  }

  #mode-control button:after {
    margin: 0 0.125rem 0 0.25rem;
  }

  #mode-control .dropdown-item {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}
