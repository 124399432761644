#x-axis {
  padding: 0 60px;
}

#x-axis hr {
  margin-top: 0;
}

.region-row {
  position: relative;
  padding: 0.5rem 0;
}

.region-icon {
  width: 0.75rem;
  height: 0.75rem;
  margin-right: 0.75rem;
  border-radius: 50%;
}

.region-name {
  font-size: 1.25rem;
  font-weight: bold;
  color: var(--gray-dark);
}

.region-days-wrapper {
  position: relative;
  overflow: hidden;
}

.region-days-gradient {
  position: absolute;
  z-index: 1;
  pointer-events: none;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(
    to right,
    white,
    #ffffffcc 150px,
    #ffffff00 200px,
    #ffffff00 85%,
    #ffffffcc 90%,
    white
  );
}

.region-days {
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  line-height: 1.875rem;
  color: var(--gray);
  opacity: 0.7;
}

.region-day {
  flex: 0 0 6.25%;
  text-align: center;
  white-space: nowrap;
  position: relative;
  height: 1.875rem;
}

.region-day-inner {
  position: absolute;
  width: 100%;
  text-align: center;
  left: -50%;
}

.region-shifter {
  position: absolute;
  top: 0.5rem;
  line-height: 1.875rem;
  display: flex;
}

.region-shifter button {
  padding: 0 0.125rem;
  height: 1.875rem;
  display: flex;
  align-items: center;
  border: none;
  color: var(--yellow);
}

.region-shifter button:hover,
.region-shifter button:focus,
.region-shifter button:active {
  color: var(--yellow);
  transform: scale(1.2);
}

.region-shifter button svg {
  width: 24px;
}

.region-shifter-left {
  left: -60px;
}

.region-shifter-right {
  right: -60px;
}

#region-input-row,
#add-region {
  margin-top: 1rem;
}

.region-name-wrapper {
  z-index: 2;
  position: absolute;
  width: 100%;
  justify-content: space-between;
}

.region-name-wrapper,
.region-name-wrapper-inner {
  display: flex;
  align-items: center;
}

.region-name-wrapper .delta {
  font-size: 0.75rem;
  font-weight: bold;
  text-transform: uppercase;
}

.region-name-wrapper .delta-ahead {
  color: var(--green);
}

.yellow-arrow {
  color: var(--yellow);
  font-weight: bold;
}

.yellow-arrow svg {
  width: 24px;
}

.region-name-wrapper .delta-behind {
  color: var(--red);
}

.region-name-wrapper button {
  color: var(--gray);
  opacity: 0.7;
}

.region-name-wrapper button:hover {
  color: var(--danger);
  transform: scale(1.2);
  opacity: 1;
}

.region-name-wrapper button svg {
  width: 16px;
  display: block;
}

#add-region {
  background-color: white;
}

#add-region svg {
  width: 1.25rem;
  margin-right: 0.25rem;
}

#add-region button {
  height: 2.5rem;
  border-radius: 1.25rem;
  display: flex;
  align-items: center;
}

#add-region button:hover {
  transform: scale(1.02);
}

.use-guide {
  color: var(--gray);
  text-align: center;
  padding: 1rem 0;
}

@media (prefers-color-scheme: dark) {
  #x-axis hr {
    border-color: var(--gray);
    opacity: 0.3;
  }

  .region-name {
    color: white;
  }

  .region-days-gradient {
    background: linear-gradient(
      to right,
      #1d2024,
      #1d2024cc 150px,
      #1d202400 200px,
      #1d202400 85%,
      #1d2024cc 90%,
      #1d2024
    );
  }

  #add-region {
    background-color: #1d2024;
  }
}

@media (max-width: 700px) {
  #x-axis {
    padding: 0 20px;
  }

  .region-row {
    padding: 0;
  }

  .region-name-wrapper {
    position: relative;
  }

  .region-name {
    font-size: 1rem;
  }

  .region-days-gradient {
    display: none;
  }

  .region-days-wrapper {
    line-height: 1;
  }

  .region-shifter {
    top: 1rem;
  }

  .region-shifter-left {
    left: -30px;
  }

  .region-shifter-right {
    right: -30px;
  }

  .region-shifter-left button:first-child,
  .region-shifter-right button:last-child {
    display: none;
  }

  #add-region svg {
    width: 1rem;
  }
}
