:root {
  --series-color-0: #6610f2;
  --series-color-1: #e83e8c;
  --series-color-2: #fdab14;
  --series-color-3: #37a728;
  --series-color-4: #20b0c9;
  --series-color-5: #173db8;
}

html, body {
  width: 100%;
  max-width: 100%;
  overflow-x: hidden;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.baseline-flex {
  display: flex;
  align-items: baseline;
}

button.btn {
  transition: all 0.3s cubic-bezier(.4, 0, .2, 1);
}

button * {
  pointer-events: none;
}

@media (prefers-color-scheme: dark) {
  :root {
    --series-color-0: #b485ff;
    --series-color-1: #ff6fb2;
    --series-color-2: #ffce65;
    --series-color-3: #80ff6f;
    --series-color-4: #74eaff;
    --series-color-5: #5263ff;
  }

  html, body {
    background-color: #1d2024;
  }

  button.btn-light {
    color: white;
    border-color: var(--gray-dark);
    background-color: var(--gray-dark);
  }

  button.btn-light:hover, button.btn-light:active, button.btn-light:focus {
    color: white;
    border-color: var(--gray);
    background-color: var(--gray);
  }
}
